import Swiper from "swiper";

var cabineSlider = new Swiper(".certslider__swiper-container", {
    slidesPerView: 4,
    spaceBetween: 16,
    navigation: {
        nextEl: ".certslider__arrow_next",
        prevEl: ".certslider__arrow_prev"
    },
    breakpoints: {
        640: {
            slidesPerView: 2,
            spaceBetween: 36,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 54,
        }
    }
});

// Rendo i loghi proporzionati
var images = document.querySelectorAll(".certslider__slide img");
function adjustImageWidth(image) {
    var widthBase = 150;
    var scaleFactor = 0.525;
    var imageRatio = image.naturalWidth / image.naturalHeight;

    image.width = Math.pow(imageRatio, scaleFactor) * widthBase;
}

images.forEach(adjustImageWidth);
