import magnificPopup from 'magnific-popup';

var galleria = $('.imageGallery');

// Traduzione
$.extend(true, $.magnificPopup.defaults, {
    tClose: 'Chiudi (Esc)',
    tLoading: 'Caricamento...',
    gallery: {
        tPrev: 'Precedente (Tasto freccia sinistra)',
        tNext: 'Successiva (Tasto freccia destra)',
        tCounter: '%curr% di %total%'
    },
    image: {
        tError: '<a href="%url%">L\' immagine</a> non può essere caricata.' // Error message when image could not be loaded
    },
    ajax: {
        tError: '<a href="%url%">Questo contenuto</a> non può essere caricato.' // Error message when ajax request failed
    }
});

if (galleria.length > 0) {
    galleria.magnificPopup({
        delegate: 'a', // child items selector, by clicking on it popup will open
        type: 'image',
        gallery: {
            enabled: true,
            preload: [0, 2],
            navigateByImgClick: true,
            // arrowMarkup: '<button title="%title%" type="button" class="imageGallery__arrow imageGallery__arrow_%dir%">'
            //     + '<svg xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 50">'
            //     + '<use xlink:href="assets/images/svg-symbols.svg#arrow"></use>'
            //     + '</svg>'
            //     + '</button>',
            arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
            tCounter: '<span class="mfp-counter">%curr% di %total%</span>'
        }
    });
}