var hamburger = $('.topbar__hamburger');
var navigation = $('.navigation');

// CONTROLLO se il menu è in mobile
var resizeTimer;

$(window).on('resize', function (e) {

    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {

        if (navigation.css('opacity') == 0) {
            navigation.css('transform', 'translateX(100%)');
        } else {
            navigation.css('transform', '');
        }

    }, 250);

}).trigger('resize');

// FUNZIONA D'APERTURA
function menuOpen() {

    // Attiva l'hamburger
    hamburger.addClass('is-active');
    navigation.css('transform', 'translateX(0)');

    // Posizioni hamburger
    var hamburgerLeft = hamburger.offset().left;
    var hamburgerWidth = hamburger.outerWidth();

    // Fisso l'hamburger
    hamburger.css({
        'position': 'fixed',
        'top': hamburger.offset().top,
        'right': $(window).width() - hamburgerLeft - hamburgerWidth,
    });

    // Aggiungo la classe al NAVIGATION
    navigation.addClass('navigation_is-open');

    navigation.one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd', function () {
        $(this).css('overflow', 'auto');
    })

    // Stagger link
    setTimeout(() => {
        staggerLinks();
    }, 500);
}

// FUNZIONE DI CHIUSURA
function menuClose() {
    hamburger.removeClass('is-active');
    staggerOutLink();

    setTimeout(() => {
        navigation.removeClass('navigation_is-open');
    }, 850);
    hamburger.attr('style', '');

    navigation.css('overflow', 'hidden');

    navigation.one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd', function () {
        $(this).css('transform', 'translateX(100%)');
    })
}

// Funzione per fare lo stagger degli elementi del menu
var menuItem = $('.navigation__item');

function staggerLinks() {
    menuItem.each(function (index, element) {
        var $item = $(this);

        setTimeout(() => {
            $item.addClass('animated fadeInUp');
        }, 50 * index);
    });
}

function staggerOutLink() {
    menuItem.each(function (index, element) {
        var $item = $(this);

        setTimeout(() => {
            $item.addClass('animated fadeOutDown');
        }, 50 * index);

        $item.one('animationend', function() {
            $(this).removeClass('animated fadeOutDown fadeInUp');
        })
    });
}

// Click
hamburger.on('click', function () {
    if (!navigation.hasClass('navigation_is-open')) {
        menuOpen();
    } else {
        menuClose();
    }
});