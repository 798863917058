import ScrollOut from 'scroll-out';

var elementoConLinea = $('.hasTitleLine');

elementoConLinea.each(function (index, element) {
    ScrollOut({
        targets: $(this),
        threshold: 0.8,

        onShown: function (el) {
            el.classList.add('hasTitleLine_visible');
        }
    })
});