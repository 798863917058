import Swiper from "swiper";
var $speed = 900;

var homeSlider = new Swiper(".homeSlider__swiper-container", {
    effect: "fade",
    loop: false,
    autoplay: {
        delay: 5000
    },
    speed: $speed,
    pagination: {
        el: ".homeSlider__navigation-container",
        clickable: true,
        type: "bullets",
        renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
        }
    },
    on: {
        slideChangeTransitionEnd: function () {
            var slide = $(".swiper-slide");
            var currentSlide = $(slide[this.activeIndex]);

            animaSlide(currentSlide);
        },
        init: function () {
            var slide = $(".swiper-slide");
            var currentSlide = $(slide[this.activeIndex]);

            animaSlide(currentSlide);
        },
        slideChange: function () {
            var slide = $(".swiper-slide");
            var currentSlide = $(slide[this.activeIndex]);

            rimuoviAnimazione(currentSlide);
        }
    }
});

// Funzione per animare gli elementi della slide
function animaSlide(currentSlide) {
    /* VARIABLI
    spans = elemento all'interno del title
    caption = elemento caption
    captionAnimation = data-attribute con la classe di animazione da dare
    button = bottone della slide
    buttonAnimation = data-attribute con la classe di animazione da dare
    */

    var spans = currentSlide.find(".homeSlider__titleElement span");
    var caption = currentSlide.find(".homeSlider__caption");
    var captionAnimation = caption.attr("data-animation");
    var button = currentSlide.find(".homeSlider__button");
    var buttonAnimation = button.attr("data-animation");

    // FUNZIONI

    // Per ogni span, inserisci la classe del data attribute
    spans.each(function (index, element) {
        var classeAnimazione = $(this).attr("data-animation");
        $(this).addClass(classeAnimazione);
    });

    // Aggiungi classe alla CAPTION
    caption.addClass("animated " + captionAnimation + "");

    // Aggiungi classe al BUTTON
    button.addClass("animated " + buttonAnimation + "");

    // Aggiungi la classe animated agli SPAN
    spans.addClass("animated");
}

// Funzione per rimuovere l'animazione
function rimuoviAnimazione(currentSlide) {
    /* VARIABLI
    spans = elemento all'interno del title
    caption = elemento caption
    captionAnimation = data-attribute con la classe di animazione da dare
    button = bottone della slide
    buttonAnimation = data-attribute con la classe di animazione da dare
    */

    var spans = currentSlide.find(".homeSlider__titleElement span");
    var caption = currentSlide.find(".homeSlider__caption");
    var button = currentSlide.find(".homeSlider__button");

    // Azzera la classe alla SPAN
    spans.attr("class", "");
    // Azzera la classe della CAPTION
    caption.attr("class", "homeSlider__caption");
    // Azzera la classe del BUTTON
    button.attr("class", "homeSlider__button");
}
