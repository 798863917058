import Swiper from "swiper";

var cabineSlider = new Swiper(".cabineSlider__swiper-container", {
    slidesPerView: 4,
    spaceBetween: 16,
    navigation: {
        nextEl: ".cabineSlider__arrow_next",
        prevEl: ".cabineSlider__arrow_prev"
    },
    breakpoints: {
        640: {
            slidesPerView: 2
        }
    }
});

var elementoCabina = $('.cabineSlider__slide');

elementoCabina.on('click', function () {
    var link = $(this).find('a').attr('href');

    window.location = link;
})
